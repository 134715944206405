var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "ui basic table competences" }, [
    _c("thead", [
      _c(
        "tr",
        _vm._l(_vm.thead, function(head, i) {
          return _c(
            "th",
            {
              key: i,
              staticClass: "table-header",
              class: head.classes,
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.orderTable(head.key, head.order)
                }
              }
            },
            [
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: head.order !== null,
                    expression: "head.order !== null"
                  }
                ],
                class:
                  "fas order-icon " +
                  (head.order === "desc" ? "fa-caret-down" : "fa-caret-up")
              }),
              _vm._v("\n         \n        "),
              _c("span", { domProps: { textContent: _vm._s(head.title) } })
            ]
          )
        }),
        0
      )
    ]),
    _c(
      "tbody",
      { staticClass: "basic-table-body" },
      [
        _vm.isLoading
          ? _c(
              "tr",
              _vm._l([].concat(Array(5)), function(item, k) {
                return _c(
                  "td",
                  { key: k },
                  [_c("t-placeholder", { attrs: { lines: 1 } })],
                  1
                )
              }),
              0
            )
          : _vm._l(_vm.competences, function(items, i) {
              return _c(
                "tr",
                { key: i },
                _vm._l(_vm.formatedData(items), function(item, k) {
                  return _c("router-link", {
                    key: k,
                    staticClass: "columns",
                    attrs: {
                      tag: "td",
                      to: {
                        name: "transactions.single",
                        params: {
                          id: _vm.itemId(_vm.competences[i]),
                          type: _vm.paymentMethod(_vm.competences[i])
                        }
                      }
                    },
                    domProps: { innerHTML: _vm._s(item) }
                  })
                }),
                1
              )
            })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { mapState } from 'vuex'
import { formatTransactionStatus, formatValueCurrency, formatDate, converShortStringDate } from '@/helpers'

export default {
  name: 'TCompetencesTable',
  props: {
    orderBy: {
      type: Function
    }
  },
  data () {
    return {
      thead: [
        { title: 'Status', classes: '', key: 'status', order: null },
        { title: 'Vencimento', classes: '', key: 'dueDate', order: null },
        { title: 'Doador', classes: '', key: 'donorName', order: null },
        { title: 'Tipo', classes: '', key: 'type', order: null },
        { title: '$ Valor', classes: '', key: 'amount', order: null }
      ]
    }
  },
  computed: {
    ...mapState({
      isLoading: ({ competences }) => competences.isLoading,
      competences: ({ competences }) => competences.competences,
    }),
  },
  methods: {
    itemId: item => item.id,

    paymentMethod: item => item.paymentMethod,

    formatedData (data) {
      return {
        status: formatTransactionStatus(data.status, data.statusLabel),
        dueDate: formatDate(converShortStringDate(data.dueDate)),
        donorName: `${data.customer.firstName} ${data.customer.lastName}`,
        type: data.stateLabel,
        amount: formatValueCurrency(data.totalAmount)
      }
    },

    orderTable (field, order) {
      const header = this.thead.find( head => head.key === field )
      if (!order || order === 'asc') {
        order = 'desc'
      } else {
        order = 'asc'
      }
      header.order = order

      this.thead.forEach(head => {
        if (head.key !== field) head.order = null
      })

      this.$emit('orderBy', { field, order })
    }
  }
}
